@font-face {
  font-family: 'proxima_novalight';
  src: url('./Mark Simonson - Proxima Nova Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novamedium';
  src: url('./Mark Simonson - Proxima Nova Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novasemibold';
  src: url('./Mark Simonson - Proxima Nova Semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novaregular';
  src: url('./Mark Simonson - Proxima Nova.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
